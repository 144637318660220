import qs from 'qs'
const baseHttp = function () {
  return window?.Vue?.prototype.$http
}

export const ApiVersion = {
  v5: 'v5',
}

export const API_VERSION = '/v5'
export const FILE_API_VERSION = '/v2'

export { APIStageEnum } from '../utils/enum'

const controllers = {}

export const http = {
  autoAbortRepeatGet: (
    path,
    params,
    {
      headers,
      isReturnResponseData = true,
      showWarningMessage = false,
      translateWarningMessage = false,
    } = {}
  ) => {
    const controllerKey = String(path).split('?')[0] || ''
    if (controllerKey && controllers[controllerKey]) {
      controllers[controllerKey].abort('repeat request aborted!')
    }
    controllers[controllerKey] = new AbortController()
    return baseHttp()
      .get(path, {
        params,
        signal: controllers[controllerKey].signal,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false })
        },
        headers: headers,
        showWarningMessage,
        translateWarningMessage,
      })
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  get: function (
    path,
    params,
    {
      headers,
      isReturnResponseData = true,
      showWarningMessage = false,
      translateWarningMessage = false,
    } = {}
  ) {
    return baseHttp()
      .get(path, {
        params: params,
        paramsSerializer: (value) => {
          return qs.stringify(value, { indices: false })
        },
        headers: headers,
        showWarningMessage,
        translateWarningMessage,
      })
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  head: function (path, params, { isReturnResponseData = true } = {}) {
    return baseHttp()
      .head(path, params)
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  post: function (
    path,
    params,
    {
      headers = {},
      isReturnResponseData = true,
      ignoreErrorCodes = [],
      showWarningMessage = false,
      translateWarningMessage = false,
    } = {}
  ) {
    return baseHttp()
      .post(path, params, {
        headers,
        ignoreErrorCodes,
        showWarningMessage,
        translateWarningMessage,
      })
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  put: function (path, params, { isReturnResponseData = true } = {}) {
    return baseHttp()
      .put(path, params)
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  patch: function (path, params, { isReturnResponseData = true } = {}) {
    return baseHttp()
      .patch(path, params)
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  delete: function (path, params, { isReturnResponseData = true } = {}) {
    return baseHttp()
      .delete(path, {
        data: params,
      })
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  download: function (path, params, { isReturnResponseData = true } = {}) {
    return baseHttp()
      .get(path, {
        params,
        responseType: 'blob',
        paramsSerializer: (value) => {
          return qs.stringify(value, { indices: false })
        },
      })
      .then((res) => {
        return isReturnResponseData ? res?.data : res
      })
  },
  request: function ({ method = 'get', url = '', config = {} }) {
    const options = {
      method: method,
      url: url,
    }
    return baseHttp()(Object.assign(options, config))
  },
}
