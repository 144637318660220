<template>
  <div class="box">
    <s-card
      class="login-container text-center"
      title="Log into Your Account"
    >
      <s-alert
        v-if="message"
        class="mb-24"
        type="error"
      >
        <p>
          {{ message }}
        </p>
      </s-alert>
      <s-select
        v-model="selected"
        :options="options"
        id="locale-account"
      >
        <template
          v-for="(item, index) in options"
          v-slot:[`custom-option-${index}`]
        >
          <div :key="index">
            <small> {{ item.value.username }} </small>
          </div>
        </template>
      </s-select>
      <s-button
        :loading="loading"
        button-type="primary"
        class="mt-24"
        style="width: 100%"
        id="locale-login-button"
        @click.native="autoAuthenticate"
      >
        Login
      </s-button>
    </s-card>
    <img
      src="/forward/auth/login"
      style="display: none"
    />
  </div>
</template>
<script>
const users = require('../../../../../../user.local.js')
const websiteCode = process.env.VUE_APP_WEBSITE_CODE
const env = (process.env.VUE_APP_ENVIRONMENT || 'dev').toLowerCase()

export default {
  name: 'AuthLogin',
  data() {
    return {
      loading: false,
      message: '',
      selected: null,
      options: users?.[websiteCode]?.[env] || [],
      mfaUrl: '/auth/security-questions',
    }
  },
  methods: {
    async autoAuthenticate() {
      this.loading = true
      try {
        let targetUrl = await this.login()
        targetUrl = await this.answerSecurityQuestions(targetUrl)
        this.redirectTo(targetUrl)
      } catch (e) {
        this.message = e.message
      }
      this.loading = false
    },

    async login() {
      if (!this.selected) {
        throw new Error('')
      }

      try {
        const loginUrl = '/auth/login'
        const { username, password } = this.selected
        const { data } = await this.$http.post(loginUrl, { username, password })
        this.$store.commit('setMFAPhoneNumber', data.phoneNumber)
        return data?.targetUrl || this.mfaUrl
      } catch (e) {
        throw new Error(e.response?.data?.message || 'unknown error.')
      }
    },

    async answerSecurityQuestions(targetUrl) {
      if (this.mfaUrl !== targetUrl) {
        return targetUrl
      }

      try {
        const { mfa } = this.selected
        const { data } = await this.$http.post('/auth/security-questions', {
          rememberDevice: false,
          questionList: mfa,
        })
        return data.targetUrl || '/'
      } catch (e) {
        throw new Error('MFA: ' + e.response?.data?.message || 'unknown error.')
      }
    },

    redirectTo(url) {
      window.location.href = url?.includes('/auth') ? url : '/'
    },
  },
}
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.login-container {
  width: 95%;
  margin-top: 25vh;
}
@include media-breakpoint-between(md, lg) {
  .login-container {
    width: 80%;
  }
}
@include media-breakpoint-between(lg, xl) {
  .login-container {
    width: 60%;
  }
}
@include media-breakpoint-up(xl) {
  .login-container {
    width: 40%;
  }
}
</style>
