import { API_VERSION, http, FILE_API_VERSION } from '../http.js'

export const documentAPI = {
  /**
   * Retrieves a list of documents based on the stage and provided parameters.
   * @param {string} stage - The stage of the document to query.
   * @param {GetDocumentsParams} params - The parameters for the document query.
   * @returns {Promise<DocumentPageResponse>} - A promise that resolves with the page response of documents.
   * @APICode {stage}.documents.list
   */
  listDocuments(stage, params) {
    params.pageNum ||= 1
    params.pageSize ||= 10
    return http.get(`${API_VERSION}/${stage}/documents`, params)
  },

  /**
   * Generates a token for downloading a document based on its stage.
   * @param {string} stage - The stage of the document to download.
   * @param {object} [params] - The parameters for document download token generation.
   * @param {number} [params.escalateId] - The ID related to the escalate.
   * @param {number} [params.applicationId] - The ID related to the application.
   * @param {number} [params.underwritingId] - The ID related to the underwriting.
   * @param {number} [params.loanFacilityId] - The ID related to the loan facility.
   * @param {number} [params.amendmentId] - The ID related to the amendment.
   * @param {Array<number>} [params.documentIds] - The list of document IDs.
   * @param {Array<string>} [params.categoryTypes] - The list of category types.
   * @param {Array<string>} [params.documentCodes] - The list of document codes.
   * @param {number} [params.versionId] - The version ID of the document.
   * @param {Array<number>} [params.fileStorageIds] - The list of file storage IDs.
   * @returns {Promise<DocumentDownloadTokenResponse>} - A promise that resolves with the document download token.
   * @APICode {stage}.documents.tokens.get
   */
  getDocumentDownloadToken(stage, params = {}) {
    return http.get(`${API_VERSION}/${stage}/documents/tokens`, params)
  },
  /**
   * Update the details of an existing document.
   * @param {string} stage - The stage of the document to update.
   * @param {number} documentId - The unique identifier of the document.
   * @param {UpdateDocumentRequest} updateDocumentRequest - The request payload to update the document.
   * @typedef {Object} UpdateDocumentRequest
   * @property {string} documentCode - The code of the document.
   * @property {string} originalFileName - The original file name of the document.
   * @property {string} mediaType - The media type of the document.
   * @returns {Promise<UpdateDocumentResponse>} A promise that resolves with the update document response.
   * @typedef {Object} UpdateDocumentResponse
   * @property {number} fileStorageId - The ID of the file storage.
   * @property {string} token - The token for the updated document.
   * @APICode {stage}.documents.update
   */
  updateDocument(stage, documentId, updateDocumentRequest) {
    return http.put(
      `${API_VERSION}/${stage}/documents/${documentId}`,
      updateDocumentRequest
    )
  },

  getDownloadFileUrl: (token, preview) => {
    return `/files${FILE_API_VERSION}/download?token=${token}&preview=${preview}`
  },
  checkFileDownloadable(token, preview) {
    const url = `/files${FILE_API_VERSION}/download?token=${token}&preview=${preview}`
    return http.request({
      url,
      config: {
        ignoreErrorCodes: ['any'],
        headers: {
          Accept: 'application/json, application/octet-stream',
        },
      },
    })
  },
}

/**
 * @typedef {Object} RelatedObjectDetail
 * @property {string} key1 - Detail key 1.
 * @property {string} key2 - Detail key 2.
 * @property {string} key3 - Detail key 3.
 */

/**
 * @typedef {Object} RelatedObject
 * @property {number} relatedObjId - The ID of the related object.
 * @property {string} relatedObjType - The type of the related object.
 * @property {string} label - The label of the related object.
 * @property {RelatedObjectDetail} detail - The detailed information of the related object.
 * @property {boolean} needed - Indicates whether the related object is needed.
 */

/**
 * @typedef {Object} DocumentResponse
 * @property {number} documentId - The ID of the document.
 * @property {string} documentName - The name of the document.
 * @property {string} originalFileName - The original file name of the document.
 * @property {string} mediaType - The media type of the document.
 * @property {string} documentCode - The code of the document.
 * @property {number} fileState - The state of the file.
 * @property {number} fileStorageId - The storage ID of the file.
 * @property {number} fileSize - The size of the file.
 * @property {number} fileVersionId - The version ID of the file.
 * @property {number} createdAt - The creation timestamp of the document.
 * @property {number} updatedAt - The update timestamp of the document.
 * @property {RelatedObject[]} relatedObj - The list of related objects.
 * @property {string[]} operationsAllowed - The list of allowed operations.
 * @property {string} originalDocumentGroup - The original document group.
 * @property {string} uploadBy - The uploader of the document.
 * @property {string} documentSourceType - The source type of the document.
 */

/**
 * @typedef {Object} GetDocumentsParams
 * @property {number} pageNum - The page number to retrieve.
 * @property {number} pageSize - The size of each page.
 * @property {string} [sort] - The sorting criteria.
 * @property {Object} [filters] - The filters to apply.
 * @property {number} [filters.escalateId] - Filter by escalate ID.
 * @property {number} [filters.applicationId] - Filter by application ID.
 * @property {number} [filters.underwritingId] - Filter by underwriting ID.
 * @property {number} [filters.loanFacilityId] - Filter by loan facility ID.
 * @property {number} [filters.amendmentId] - Filter by amendment ID.
 * @property {string} [filters.categoryType] - Filter by category type.
 * @property {string[]} [filters.documentCodes] - Filter by document codes.
 * @property {number[]} [filters.counterOfferIdList] - Filter by counter offer ID list.
 * @property {number[]} [filters.noticeOfIncompleteIdList] - Filter by notice of incomplete ID list.
 * @property {number[]} [filters.appPeIdList] - Filter by app PE ID list.
 * @property {number[]} [filters.locPeIdList] - Filter by loc PE ID list.
 * @property {number[]} [filters.amendmentPeIdList] - Filter by amendment PE ID list.
 * @property {number[]} [filters.evaluationAssetIdList] - Filter by evaluation asset ID list.
 * @property {number[]} [filters.assetIdList] - Filter by asset ID list.
 * @property {number[]} [filters.amendmentAssetIdList] - Filter by amendment asset ID list.
 */

/**
 * @typedef {Object} DocumentPageResponse
 * @property {number} pageNumber - The current page number.
 * @property {number} pageSize - The size of each page.
 * @property {number} totalPages - The total number of pages.
 * @property {number} totalItems - The total number of items.
 * @property {DocumentResponse[]} items - The list of documents.
 */

/**
 * @typedef {Object} DocumentDownloadTokenResponse
 * @property {string} token - The token for downloading the document.
 */
