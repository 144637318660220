<template>
  <div class="box">
    <s-card
      class="login-container text-center"
      title="Choose Profile"
    >
      <s-alert
        v-if="message"
        class="mb-24"
        type="error"
      >
        <p>
          {{ message }}
        </p>
      </s-alert>
      <s-loading :loading="loading">
        <s-select
          v-model="profileId"
          :options="options"
          id="locale-profile"
        >
          <template
            v-for="(item, index) in options"
            v-slot:[`custom-option-${index}`]
          >
            <div :key="index">
              <small> {{ item.profileName }} </small>
              <small> {{ item.roleType }} </small>
            </div>
          </template>
        </s-select>
        <s-button
          button-type="primary"
          class="mt-24"
          style="width: 100%"
          :loading="submitting"
          @click.native="submit"
          id="locale-login-button"
        >
          Confirm
        </s-button>
      </s-loading>
    </s-card>
  </div>
</template>
<script>
export default {
  name: 'ChooseProfile',
  data() {
    return {
      loading: false,
      submitting: false,
      profileId: null,
      options: [],
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    async getOptions() {
      this.loading = true
      const { data } = await this.$http.get('/auth/profiles').catch((e) => {
        this.message = e.response?.data?.message || 'unknown error.'
      })
      const items = data?.items || []
      this.options = items.map((item) => {
        return {
          value: item.profileId,
          text: `${item.roleName}`,
          profileName: item.profileName,
          roleType: item.roleType,
        }
      })
      this.loading = false
    },
    submit() {
      this.submitting = true
      this.$http
        .post('/auth/login-profile', { profileId: this.profileId })
        .then(() => {
          window.location.href = '/'
        })
        .catch((e) => {
          this.message = e.response?.data?.message || 'unknown error.'
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.login-container {
  width: 95%;
  margin-top: 25vh;
}
@include media-breakpoint-between(md, lg) {
  .login-container {
    width: 80%;
  }
}
@include media-breakpoint-between(lg, xl) {
  .login-container {
    width: 60%;
  }
}
@include media-breakpoint-up(xl) {
  .login-container {
    width: 40%;
  }
}
</style>
